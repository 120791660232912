import React from "react";
import { SWRConfig } from "swr";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import store from "./src/redux/store";
import ChatApp from "./src/components/chatApp/ChatApp";
import { navigate } from "gatsby";

const swrConfig = {
  revalidateOnFocus: false,
  shouldRetryOnError: false,
  revalidateOnReconnect: true,
  fetcher: (url) => fetch(url).then((r) => r.json()),
};

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <SWRConfig value={swrConfig}>
        <ChatApp>{element}</ChatApp>
        <ToastContainer
          position="top-right"
          className="toastify_container"
          toastClassName="toastify_root"
          bodyClassName="toastify_body"
          hideProgressBar
          closeButton={false}
          closeOnClick={false}
        />
      </SWRConfig>
    </Provider>
  );
};

export function wrapPageElement({ element, props }) {
  const Layout = element.type.Layout ?? React.Fragment;
  return <Layout {...props}>{element}</Layout>;
}

const initListener = () => {
  const eventMethod = window.addEventListener
    ? "addEventListener"
    : "attachEvent";
  const eventer = window[eventMethod];
  const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

  const listenerFunction = async function (e) {
    const key = e.message ? "message" : "data";
    const data = e[key];

    if (data.from === "bc" && data.type === "loadCart") {
      navigate("/cart");
    }

    if (data.from === "bc" && data.type === "homePageRedirect") {
      navigate("/");
      // critical: used for checkout issue.
      window.location.reload();
    }
  };

  eventer(messageEvent, listenerFunction, false);

  return () => {
    eventer(messageEvent, listenerFunction, false);
  };
};

const hardRefresh = () => {
  window.location.href = window.location.href;
  window.location.reload(true);
};

const checkTimeToHardRefresh = () => {
  const firstLoadTime = localStorage.getItem("mw_lastreload");
  if (!firstLoadTime) {
    localStorage.setItem("mw_lastreload", new Date().getTime());
  } else {
    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - parseInt(firstLoadTime);

    const refreshThreshold = 2 * 60 * 60 * 1000;

    if (timeElapsed >= refreshThreshold) {
      localStorage.setItem("mw_lastreload", new Date().getTime());
      hardRefresh();
    }
  }
};

const checkRedirect = () => {
  const currentPath = window.location.pathname;

  const res = fetch(
    `${process.env.GATSBY_STOREFRONT_API_URL}/redirects/edgefunctions?path=${currentPath}`
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.redirect) {
        // redirect to the new path...
        navigate(data.redirect);
      }
    });

  return res;
};

export const onRouteUpdate = () => {
  // redirect check on every route change ...
  checkRedirect();

  // Hide iframe on every page...
  // iframe will only visible for after clicking on proceed to checkout...
  const checkoutIframe = document.querySelector("#checkout-iframe");

  if (checkoutIframe) {
    if (window.location.href.includes("checkout")) {
      checkoutIframe.style.display = "block";
    } else {
      checkoutIframe.style.display = "none";
    }
  }

  if (window.location.pathname.includes("/checkout")) {
    initListener();
  }

  checkTimeToHardRefresh();
};

export const onServiceWorkerUpdateReady = () => {
  hardRefresh();
};

export const onInitialClientRender = async () => { };
